exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bildgalleri-fonster-js": () => import("./../../../src/pages/bildgalleri/fonster.js" /* webpackChunkName: "component---src-pages-bildgalleri-fonster-js" */),
  "component---src-pages-bildgalleri-index-js": () => import("./../../../src/pages/bildgalleri/index.js" /* webpackChunkName: "component---src-pages-bildgalleri-index-js" */),
  "component---src-pages-bildgalleri-takfonster-js": () => import("./../../../src/pages/bildgalleri/takfonster.js" /* webpackChunkName: "component---src-pages-bildgalleri-takfonster-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

